<template>
  <loader-view v-if="loading" />

  <section v-else class="py-12">
    <v-container class="py-0">
      <v-row class="mb-8">
        <v-col cols="12" sm="5" md="3" lg="2" xl="1">
          <div class="d-flex align-center justify-cente">
            <v-avatar class="border" color="accent" size="100%" tile>
              <v-img
                :src="league.image || require('@/assets/league/empty-logo.png')"
                :aspect-ratio="4 / 4"
              />
            </v-avatar>
          </div>
        </v-col>

        <v-col>
          <div
            class="d-flex flex-column fill-height justify-center pl-0 pl-md-8"
          >
            <h1 class="text-h4 overtext--text">{{ league.name }}</h1>
            <div class="text-h6 font-weight-regular subtext--text mb-4">
              {{ league.description }}
            </div>

            <div class="d-flex">
              <v-btn
                class="text-none black--text"
                color="primary"
                depressed
                @click="editLeague()"
              >
                <v-icon left> mdi-pencil</v-icon>
                Editar Liga
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-card color="surface" class="mb-10" flat tile>
      <v-container class="pb-0 pt-6">
        <v-tabs
          background-color="transparent"
          slider-color="primary"
          color="subtext"
          show-arrows
        >
          <v-tab
            v-for="(item, i) in tabs"
            exact-active-class="overtext--text"
            :key="i"
            :to="`/league/league-details/${$route.params.id}${item.path}`"
          >
            {{ item.label }}
          </v-tab>
        </v-tabs>
      </v-container>
    </v-card>

    <v-container class="py-0">
      <router-view :league="league" @refresh="getData()" />

      <EditLeague ref="editLeague" @success="getData()" />
    </v-container>
  </section>
</template>

<script>
import { getLeague } from "@/services/league/leagues.js";
import EditLeague from "@/components/league/leagues/EditLeague.vue";

export default {
  data() {
    return {
      loading: true,
      league: null,
      tabs: [
        {
          label: "Geral",
          path: "/",
        },
        {
          label: "Tabela",
          path: "/table",
        },
        {
          label: "Equipes",
          path: "/teams",
        },
        {
          label: "Participantes",
          path: "/players",
        },
      ],
    };
  },

  components: {
    EditLeague,
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        await getLeague(this.$route.params.id).then((res) => {
          this.league = res;
          this.loading = false;
        });
      } catch (err) {
        console.log(err);
        this.$router.push({ path: "/league/leagues" });
      }
    },

    editLeague() {
      this.$refs.editLeague.handleDialog(this.league);
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  border: 1px white solid !important;
}
</style>
